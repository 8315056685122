import React from "react";
import { Route, Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 mt-5 pt-5">
            <h1>Thank you.</h1>
            <p>
              By entering your email details you can now view the requested
              document on the previous web page, just click the red
              icon - please close this page and continue.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;