import React from "react";
import AdminNav from "../../components/nav/AdminNav";

const AdminDashboard = () => {

    return (
        <div className="container-fluid p-0">
          <div className="row mx-0 angle-product">
  </div>
    <div className="row mt-5">
      <div className="col-md-2">
        <AdminNav />
      </div>

      <div className="col">
      <h4>Admin Dashboard</h4>
        </div>
    </div>
  </div>
    );
};

export default AdminDashboard;