import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrUpdateUser } from '../../functions/auth';

const Login = ({history}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => ({...state }));

  useEffect(() => {
        if (user && user.token) {
            history.push("/");
            }
            }, [user, history]);

  let dispatch = useDispatch();

  const roleBasedRedirect = (res) => {
  if(res.data.role === 'Administrator') {
    history.push('/admin/products');
  }
};

const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // console.table(email, password);
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      // console.log(result);
      const {user} = result;
      const idTokenResult = await user.getIdTokenResult();

      createOrUpdateUser(idTokenResult.token)
      .then((res) => {
            dispatch({
            type: 'LOGGED_IN_USER',
            payload: {
              name: res.data.name,
              email: res.data.email,
              token: idTokenResult.token,
              role: res.data.role,
              _id: res.data._id,
           },
         });
         roleBasedRedirect(res);
      })
      .catch(err => console.log(err));

  
  // history.push('/');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const loginForm = () => (
  <form onSubmit={handleSubmit}>
       <input
       type="email"
       className="form-control"
       value ={email}
       onChange={(e) => setEmail(e.target.value)}
       placeholder="Your email"
       autoFocus
       />

       <br />

       <input
       type="password"
       className="form-control"
       value ={password}
       onChange={(e) => setPassword(e.target.value)}
       placeholder="Your password"
       autoFocus
       />

       <br />

    <Button 
    onClick={handleSubmit}
    type="primary"
    htmlType="submit"
    className="mb-3"
    block
    size="large"
    disabled={!email || password.length < 6}
    >
    Login
    </Button>
  </form>
  );
  return (
    <div className="container p-5">
    <div className="row">
      <div className="col-md-6 offset-md-3">
      {loading ? (
        <h4 className="blue">Loading...</h4>
        
        ) : (
          <h4>Login</h4>
        )}
      {loginForm()}
      <Link to="/forgot/password" className="float-right blue">Forgot Password</Link>
</div>
    </div>
    </div>
  );
};

export default Login;