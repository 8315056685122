import React from "react";
import { Card } from "antd";
import defaul from "../../images/default-trackelast.png"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Meta } = Card;

const AdminProductCard = ({ product, handleRemove }) => {

    const { ident, description, images, slug } = product;

    return (
        <Card cover={
            <img src={images && images.length ? images[0].url : defaul}
            style={{ height: "150px", objectFit: "cover"}}
            className="p-1"
            alt="Default for missing products"
             />
             }
             actions={[
                 <Link to={`/admin/product/${slug}`}>
                 <EditOutlined className="text-warning" />
                 </Link>,
                 <DeleteOutlined onClick={() => handleRemove(slug)} className="text-danger" />,
             ]}
            >
        <Meta title={ident} description={`${description && description.substring(0, 35)}...`} />
        </Card>
    );
};

export default AdminProductCard;