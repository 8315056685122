import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.less";

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import NavBar from "./components/nav/NavBar";
import Footer from "./components/home/Footer";
import RegisterComplete from "./pages/auth/RegisterComplete";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AdminRoute from "./components/routes/AdminRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import CategoryCreate from "./pages/admin/category/CategoryCreate";
import CategoryUpdate from "./pages/admin/category/CategoryUpdate";
import SubCreate from "./pages/admin/sub/SubCreate";
import SubUpdate from "./pages/admin/sub/SubUpdate";
import ProductCreate from "./pages/admin/product/ProductCreate";
import AllProducts from "./pages/admin/product/AllProducts";
import ProductUpdate from "./pages/admin/product/ProductUpdate";
import Product from "./pages/Product";
import CategoryHome from "./pages/category/CategoryHome";
import SubHome from "./pages/sub/SubHome";
import ProductApplication from "./pages/ProductApplication";
import ApplicationHeavy from "./pages/ApplicationHeavy";
import ApplicationBridge from "./pages/ApplicationBridge";
import ApplicationTunnel from "./pages/ApplicationTunnel";
import ApplicationCrane from "./pages/ApplicationCrane";
import ApplicationTurnout from "./pages/ApplicationTurnout";
import Search from "./pages/Search";
import DesignTesting from "./pages/DesignTesting";
import Accreditations from "./pages/Accreditations";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CookiePolicy from "./pages/CookiePolicy";
import ThankYou from "./pages/ThankYou";
import DesignGuidance from "./pages/DesignGuidance";

import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import HamburgerMenu from "./components/nav/HumburgerMenu";
import ScrollToTop from "./components/nav/ScrollToTop";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        console.log("user", user);
        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <HamburgerMenu />
      <div>
        <ToastContainer />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/register/complete" component={RegisterComplete} />
          <Route exact path="/forgot/password" component={ForgotPassword} />
          <AdminRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminRoute exact path="/admin/category" component={CategoryCreate} />
          <AdminRoute
            exact
            path="/admin/category/:slug"
            component={CategoryUpdate}
          />
          <AdminRoute exact path="/admin/sub" component={SubCreate} />
          <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
          <AdminRoute exact path="/admin/product" component={ProductCreate} />
          <AdminRoute exact path="/admin/products" component={AllProducts} />
          <AdminRoute
            exact
            path="/admin/product/:slug"
            component={ProductUpdate}
          />
          <Route exact path="/product/:slug" component={Product} />
          <Route exact path="/category/:slug" component={CategoryHome} />
          <Route exact path="/sub/:slug" component={SubHome} />
          <Route exact path="/applications" component={ProductApplication} />
          <Route exact path="/applications/1" component={ApplicationHeavy} />
          <Route exact path="/applications/2" component={ApplicationBridge} />
          <Route exact path="/applications/3" component={ApplicationTunnel} />
          <Route exact path="/applications/4" component={ApplicationCrane} />
          <Route exact path="/applications/5" component={ApplicationTurnout} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/design-testing" component={DesignTesting} />
          <Route exact path="/accreditations" component={Accreditations} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/design-guidance" component={DesignGuidance} />
        </Switch>
        <ScrollToTop />
      </div>
      <Footer />
    </>
  );
};

export default App;
