import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getProduct, updateProduct } from "../../../functions/product";
import { getCategories, getCategorySubs } from "../../../functions/category";
import FileUpload from "../../../components/forms/FileUpload";
import { LoadingOutlined } from "@ant-design/icons";
import ProductUpdateForm from "../../../components/forms/ProductUpdateForm";

const initialState = {
  title: "",
  ident: "",
  sorty: "",
  description: "",
  descriptiontwo: "",
  categories: [],
  category: "",
  subs: [],
  images: [],
  featurea: "",
  featureb: "",
  featurec: "",
  featured: "",
  designa: "",
  designb: "",
  designc: "",
  designd: "",
};

const ProductUpdate = ({ match, history }) => {
  const [values, setValues] = useState(initialState);
  const [categories, setCategories] = useState([]);
  const [subOptions, setSubOptions] = useState([]);
  const [arrayOfSubs, setArrayOfSubs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  
  const { user } = useSelector((state) => ({ ...state }));
  // router
  const { slug } = match.params;

  useEffect(() => {
    loadProduct();
    loadCategories();
  }, []);

  const loadProduct = () => {
    getProduct(slug).then((p) => {
      // console.log("single product", p);
      setValues({ ...values, ...p.data });
      getCategorySubs(p.data.category._id).then((res) => {
        setSubOptions(res.data);
      });
      let arr = [];
      p.data.subs.map((s) => {
        arr.push(s._id);
      });
      console.log("ARR", arr);
      setArrayOfSubs((prev) => arr);
    });
  };

  const loadCategories = () =>
    getCategories().then((c) => {
      console.log("Get Categories for updated product", c.data);
      setCategories(c.data);
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    values.subs = arrayOfSubs;
    values.category = selectedCategory ? selectedCategory : values.category;

    updateProduct(slug, values, user.token)
    .then((res) => {
      setLoading(false);
      toast.success(`"${res.data.title}" is updated`);
      history.push("/admin/products");
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      toast.error(err.response.data.err);
    });
  };

   const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ------ ", e.target.value);
  };

    const handleCategoryChange = (e) => {
    e.preventDefault()
    console.log('CLICKED CATEGORY', e.target.value);
    setValues({ ...values, subs: [] });

    setSelectedCategory(e.target.value);

    getCategorySubs(e.target.value).then(res => {
      console.log("SUB OPTIONS ON CATEGORY CLICK", res);
      setSubOptions(res.data);
    });

    console.log("Existing Category values.category", values.category);

    if (values.category._id === e.target.value) {
      loadProduct();
    }
    setArrayOfSubs([]);
  };

  return (
    <div className="container-fluid p-0">
          <div className="row mx-0 angle-product">
  </div>
  <div className="container">
    <div className="row mt-5">
      <div className="col-md-2">
        <AdminNav />
      </div>

        <div className="col-md-10">
          {loading ? (
          <LoadingOutlined className="blue h1" />
          ) : (
             <h4>Update application </h4>
          )}
           {/* {JSON.stringify(values)} */}

           <div className="p-3">
          <FileUpload values={values} setValues={setValues} setLoading={setLoading} />
          </div>

          <ProductUpdateForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setValues={setValues}
          values={values}
          handleCategoryChange={handleCategoryChange}
          categories={categories}
          subOptions={subOptions}
          arrayOfSubs={arrayOfSubs}
          setArrayOfSubs={setArrayOfSubs}
          selectedCategory={selectedCategory}

          />
          <hr />
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProductUpdate;