import React from "react";
import { Card, Space } from "antd";
// import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import defaul from "../../images/default-trackelast.png";
import FeatureListItems from "./FeatureListItems";
import DesignListItems from "./DesignListItems";
import ThreeModal from "../modals/ThreeModal";

const { Meta } = Card;

const SingleProduct = ({ product }) => {
  const { title, images, description, descriptiontwo } = product;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mt-n5 pr-5 pl-5 pb-5">
            <div className="carousel-wrapper">
            {images && images.length ? (
              <Carousel
                showArrows={true}
                showThumbs={false}
                autoPlay
                infiniteLoop
                showStatus={false}
              >
                {images &&
                  images.map((i) => (
                    <img
                      src={i.url}
                      key={i.public_id}
                      alt="Carousel images of Trackelast product"
                    />
                  ))}
              </Carousel>
            ) : (
              <Card
                cover={
                  <img
                    src={defaul}
                    className="mb-3 card-image"
                    alt="Trackelast default if uploaded product is missing imagery"
                  />
                }
              ></Card>
            )}
            </div>
          </div>

          <div className="col-md-6 pl-0">
            <h1 className="pt-1 blue">{title}</h1>
            <Card bordered={false} bodyStyle={{ padding: "0" }}>
              <Meta description={description} className="mb-4"/>
              <Meta description={descriptiontwo} />
              {/* <FeatureListItems product={product} /> */}
              {product.sketchfab || product.video ? (
                <ThreeModal
                  title={title}
                  sketchfab={product.sketchfab}
                  mp4File={product.video}
                />
              ) : null}
            </Card>
          </div>
        </div>
      </div>

      <div className="container-fluid bgstill mt-5 p-0">
        <div className="row">
          <div className="col-md-12 bgstill">
            <div className="container">
              <div className="row pt-5 pb-5">
                <div className="col-md-4 mb-5 offset-1">
                  <h3 className="blue">Key Features</h3>
                  <FeatureListItems product={product} />
                </div>
                <div className="col-md-4 offset-1">
                  <h3 className="blue">Design Requirements</h3>
                  <DesignListItems product={product} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default SingleProduct;
