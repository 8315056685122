import React from "react";
import { Link } from "react-router-dom";

const AdminNav = () => (
  
  <nav>
    <h6 className="blue pl-3">Admin Menu</h6>
    <ul className="nav flex-column">
      {/* <li className="nav-item">
        <Link to="/admin/sols" className="nav-link text-dark">
          View All Solutions
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sol" className="nav-link text-dark">
          Create New Solution
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/solution" className="nav-link text-dark">
          Create Solution Category
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sub-solution" className="nav-link text-dark">
          Create Solution Sub Category
        </Link>
      </li> */}

      <li className="nav-item">
        <Link to="/admin/products" className="nav-link text-dark">
          View All Applications
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/product" className="nav-link text-dark">
          Create New Application
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/category" className="nav-link text-dark">
          Create Application Category
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sub" className="nav-link text-dark">
          Create Application Sub Category
        </Link>
      </li>

    </ul>
  </nav>
);

export default AdminNav;
