import React, { useEffect, useRef } from "react";
import HomepageModal from "../components/modals/HomepageModal";
import { Button } from "antd";
import light from "../images/Tracklast-light-rail-application-tab.jpg";
import heavy from "../images/Tracklast-heavy-rail-application-tab.jpg";
import bridge from "../images/Tracklast-bridge-application-tab.jpg";
import tunnel from "../images/Tracklast-tunnel-application-tab.jpg";
import crane from "../images/Tracklast-crane-application-tab.jpg";
import turnout from "../images/Tracklast-turnout-application-tab.jpg";
import test from "../images/trackelast-in-house-design-testing.jpg";
import {
  TeamOutlined,
  CommentOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
// import YoutubeEmbed from "../components/videos/YoutubeEmbed";
import { NavLink, useLocation } from "react-router-dom";

// const { Meta } = Card;

const Home = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  const location = useLocation();
  const [activeKey, setActiveKey] = React.useState(location.pathname);

  useEffect(() => {
    attemptPlay();
    setActiveKey(location.pathname);
  }, [location]);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle">
          <div className="container p-0">
            <div className="row mt-5 flex-lg-row-reverse">
              <div className="col-lg-5 angle-ret mt-5 p-0">
                <h1 className="h1-pad">Specialist Rail Solutions</h1>
                <p className="lead white p-pad">
                  Specialising since 1946 in the manufacture of resilient track
                  support matrials and other components which are designed to
                  reduce ground-borne vibration from rail track&#46;
                </p>
              </div>
              <div className="col-lg-7 mt-5 angle-ret mb-1 text-center">
                <video
                  style={{
                    maxWidth: "100%",
                    width: "800px",
                    margin: "0 auto",
                    border: "1px solid #E9F10B",
                  }}
                  playsInline
                  loop
                  muted
                  controls
                  alt="Discover what Trackelast can do for track support materials and components"
                  src="https://res.cloudinary.com/dkfjd6cdn/video/upload/v1691502028/track-video/All_Trackelast_items_V2_iaiaek.webm"
                  ref={videoEl}
                />
                <p className="text-center text-white ml-2 mb-5">
                  Click button below to view Interactive model
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-0">
          <div className="row mt-n4">
            <div className="col-lg-7 pt-n5 text-center">
              <HomepageModal />
            </div>
            <div className="col-lg-5"></div>
          </div>
        </div>
        <br />
        <br />
        <div className="container p-0">
          <div className="row m-0 p-0 mt-4">
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications",
                  state: { product: "light-rail" },
                  sort: { sorty: 1 },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Light Rail</h4>
                  <p>
                    Tramways&#44; Metros and rapid transit systems&#44; using a
                    variety of track gauges&#44; typically operating smaller
                    frequent passenger trains in an urban area&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img src={light} alt="Light rail applications and products" />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications/1",
                  state: { product: "heavy-rail" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Heavy Rail</h4>
                  <p>
                    Conventional railways forming part of the national network
                    including Inter-City&#44; High Speed&#44; Regional Rail and
                    Freight services&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img src={heavy} alt="Heavy rail applications and products" />
                </div>
              </NavLink>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications/2",
                  state: { product: "bridges" },
                  sort: { sorty: 1 },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Bridges</h4>
                  <p>
                    Structures over and under the railway requiring
                    waterproofing&#44; structure protection and other specialist
                    products&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mt-3 mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={bridge}
                    alt="Rail Bridge applications and products"
                  />
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications/3",
                  state: { product: "tunnels" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Tunnels</h4>
                  <p>
                    Applies to all railway systems&#44; incorporating such
                    systems as floating slab track&#44; gap fillers and other
                    products&#44; ideally constructed from fire retardant
                    material&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={tunnel}
                    alt="Trackelast are the long term UK Distributor for Delkor
                    Baseplates and associated products."
                  />
                </div>
              </NavLink>
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications/4",
                  state: { product: "cranes" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Cranes</h4>
                  <p>
                    Crane systems in industrial situations and container
                    Ports/Harbours&#46; Typically&#44; heavy tonnage
                    applications&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img src={crane} alt="#" />
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <NavLink
                to={{
                  pathname: "applications/5",
                  state: { product: "turnouts" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Applications</h6>
                  <h4>Turnouts</h4>
                  <p>
                    A railway switch&#44; turnout&#44; or &#39;set of&#39;
                    points is a mechanical installation enabling railway trains
                    to be guided from one track to another&#46;
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    View Applications
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={turnout}
                    alt="Trackelast are the long term UK Distributor for Delkor
                    Baseplates and associated products."
                  />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="container-fluid bgstill p-0">
          <div className="container mt-5 mb-5 p-0">
            <div className="row pt-5 pb-5 align-items-center">
              <div className="col-lg-7 mt-5 justify-content-center">
                <img
                  className="img-fluid"
                  src={test}
                  alt="Testing Trackelast products inside the Tiflex laboratory"
                />
              </div>
              <div className="col-lg-5 pr-5">
                <div className="col mt-5 mb-5">
                  <h2 className="pl-5 blue">In-house Design &amp; Testing</h2>
                  <p className="mb-3 pl-5 pt-2 blue">
                    Our polymer material specialists plus in-house design and
                    testing facilities at our modern facility in Cornwall allow
                    us to develop bespoke new grades to meet the ever changing
                    requirements of our customers.
                  </p>
                  <NavLink to="design-testing">
                    <Button type="primary" size="large" className="ml-5 mt-2">
                      Find Out More
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="container">
          <h2 className="text-center pt-5 pb-5 mb-0 blue">About Us</h2>
          {/* <div className="container"> */}
          <div className="row text-center justify-content-center">
            <div className="col-md-4">
              <h6 className="pt-5 blue">OVER 75 YEARS EXPERIENCE</h6>
              <br />
              <TeamOutlined className="blue" style={{ fontSize: "800%" }} />
              <p className="pt-5 pb-3 blue">
                Tiflex have over 75 years of experience in designing &
                manufacturing rail support materials to the global rail industry
                under the brand name Trackelast.
              </p>
            </div>
            <div className="col-md-4">
              <h6 className="pt-5 blue">QUALITY MANAGEMENT SYSTEM</h6>
              <br />
              <SafetyCertificateOutlined
                className="blue"
                style={{ fontSize: "800%" }}
              />
              <p className="pt-5 pb-3 blue">
                Tiflex / Trackelast are certified by BSI to; ISO 9001, ISO 14001
                & ISO 45001 and are also RISQS accredited.
              </p>
            </div>
          </div>

          <div className="row p-5 mt-0 mb-0 justify-content-center">
            <NavLink to="about-us">
              <Button type="primary" size="large">
                Learn More About Us
              </Button>
            </NavLink>
          </div>
        </div>
        {/* </div> */}
        <br />
        <br />

        <div className="container-fluid p-0">
          <div className="col back">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center mt-5 pt-5 pt-3">
                  {" "}
                  <CommentOutlined
                    className="white"
                    style={{ fontSize: "1200%" }}
                  />
                </div>
                <div className="col-md-8 mt-5 pt-5 pb-5">
                  <h3 className="white">Trackelast's Accreditations</h3>
                  <p className="white lead">
                    Tiflex / Trackelast are RISQS accredited. We also hold
                    numerous approvals for companies including Network Rail and
                    London Underground and have many Grandfather Rights
                    &#40;Historical approval&#41; for older Rail Pads.
                  </p>
                  <NavLink to="accreditations">
                    <Button type="primary" size="large" className="mt-2">
                      More Information
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default Home;
