import React from "react";
import { Select } from "antd";

const { Option } = Select;

const ProductUpdateForm = ({
  handleSubmit,
  handleChange,
  handleFileChange,
  setValues,
  values,
  handleCategoryChange,
  categories,
  subOptions,
  arrayOfSubs,
  setArrayOfSubs,
  selectedCategory,
}) => {
  // destructure
  const {
    title,
    ident,
    sorty,
    description,
    descriptiontwo,
    category,
    featurea,
    featureb,
    featurec,
    featured,
    designa,
    designb,
    designc,
    designd,
  } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={title}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>ID</label>
        <input
          type="text"
          name="ident"
          className="form-control"
          value={ident}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Sort number</label>
        <input
          type="number"
          name="sorty"
          className="form-control"
          value={sorty}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Description 1</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={description}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label>Description 2</label>
        <input
          type="text"
          name="descriptiontwo"
          className="form-control"
          value={descriptiontwo}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label>Category</label>
        <select
          name="category"
          className="form-control"
          onChange={handleCategoryChange}
          value={selectedCategory ? selectedCategory : category._id}
        >
          {categories.length > 0 &&
            categories.map((c) => (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>

      <div>
        <label>Sub Categories</label>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Please select"
          value={arrayOfSubs}
          onChange={(value) => setArrayOfSubs(value)}
        >
          {subOptions.length &&
            subOptions.map((s) => (
              <Option key={s._id} value={s._id}>
                {s.name}
              </Option>
            ))}
        </Select>
      </div>

      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Key Feature 1</label>
          <input
            type="text"
            name="featurea"
            className="form-control"
            placeholder="At least 1 key feature must be entered"
            value={featurea}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 2</label>
          <input
            type="text"
            name="featureb"
            className="form-control"
            value={featureb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 3</label>
          <input
            type="text"
            name="featurec"
            className="form-control"
            value={featurec}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 4</label>
          <input
            type="text"
            name="featured"
            className="form-control"
            value={featured}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Design Requirement 1</label>
          <input
            type="text"
            name="designa"
            className="form-control"
            placeholder="At least 1 design requirement must be entered"
            value={designa}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 2</label>
          <input
            type="text"
            name="designb"
            className="form-control"
            value={designb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 3</label>
          <input
            type="text"
            name="designc"
            className="form-control"
            value={designc}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 4</label>
          <input
            type="text"
            name="designd"
            className="form-control"
            value={designd}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <div className="row pl-3">
        <label className="btn btn-primary mr-2">
          Attach Brochure / Flyer &#40;PDF&#41;
          <input
            name="brochure"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
        <label className="btn btn-primary mr-2">
          Attach Product Matrix &#40;PDF&#41;
          <input
            name="datasheet"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <br />
      <button className="btn btn-outline-info">Save</button>
    </form>
  );
};

export default ProductUpdateForm;
