import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import Search from "../forms/Search";
import trackelastLogo from "../../images/Trackelast-Logo.svg";

const { SubMenu, Item } = Menu;

const NavBar = () => {
  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  let dispatch = useDispatch();
  let { user } = useSelector((state) => ({ ...state }));
  let history = useHistory();

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const handleSubmenuClick = (e) => {
    // Set the active state only if it's a top-level menu item
    if (!e.keyPath || e.keyPath.length === 1) {
      setCurrent(e.key);
    }
  };

  // const handleClick = (e) => setCurrent(e.key);

  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    history.push("/login");
  };

  return (
    <div className="nav-bar">
      <div
        style={{
          float: "left",
          marginLeft: "-20px",
          marginTop: "-15px",
          width: "220px",
          height: "95px",
          position: "relative",
          background: "rgba(255, 255, 255, 1)",
          zIndex: "100",
        }}
      >
        <Link to="/">
          <img
            className="col-swap"
            src={trackelastLogo}
            alt="Trackelast logo"
          />
        </Link>
      </div>
      <Menu
        onClick={handleSubmenuClick}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        className="d-flex justify-content-end"
      >
        <Item key="/">
          <Link exact to="/">
            HOME
          </Link>
        </Item>

        <SubMenu key="SubMenu" title="APPLICATIONS">
            <Item key="/applications"><Link exact to={{ pathname: "/applications", state: { product: "light-rail" }, }} >Light Rail</Link></Item>
            <Item key="/applications/1"><Link exact to={{ pathname: "/applications/1", state: { product: "heavy-rail" }, }} >Heavy Rail</Link></Item>
            <Item key="/applications/2"><Link exact to={{ pathname: "/applications/2", state: { product: "bridges" }, }} >Bridges</Link></Item>
            <Item key="/applications/3"><Link exact to={{ pathname: "/applications/3", state: { product: "tunnels" }, }} >Tunnels</Link></Item>
            <Item key="/applications/4"><Link exact to={{ pathname: "/applications/4", state: { product: "cranes" }, }}>Cranes</Link></Item>
            <Item key="/applications/5"><Link exact to={{ pathname: "/applications/5", state: { product: "turnouts" }, }}>Turnouts</Link></Item>
        </SubMenu>

        <Item key="/about-us">
          <Link to="/about-us">ABOUT US</Link>
        </Item>
        <Item key="/design-testing">
          <Link to="/design-testing">DESIGN & TESTING</Link>
        </Item>
        <Item key="/contact-us">
          <Link to="/contact-us">CONTACT US</Link>
        </Item>
        <Item key="/accreditations">
          <Link to="/accreditations">ACCREDITATIONS</Link>
        </Item>
        {user && (
          <SubMenu key="SubMenu5" title="Account">
            {user && user.role === "Administrator" && (
              <Item>
                <Link to="/admin/products">Admin Menu</Link>
              </Item>
            )}
            <Item key="/register">
              <Link to="/register">reg</Link>
            </Item>
            <Item key="/login">
              <Link to="/login">login</Link>
            </Item>
            <Item onClick={logout}>logout</Item>
          </SubMenu>
        )}
        {/* <span className="float-right p-1"> */}
        {/* <Search /> */}
        {/* </span> */}
      </Menu>
    </div>
  );
};

export default NavBar;
