import React, { useEffect, useState } from "react";
import { getProductsByCount, getProductsByFilter } from "../functions/product";
import { getCategories } from "../functions/category";
import { getSubs } from "../functions/sub";
import ProductCard from "../components/cards/ProductCard";
import { Breadcrumb } from "antd";
import { Checkbox, Menu } from "antd";

const { SubMenu } = Menu;

const ApplicationBridge = (props) => {
  const productsSlug = props.location.state
    ? props.location.state.product
    : null;

  const [subjects, setSubjects] = useState([]);
  const [subsubjects, setSubsubjects] = useState([]);
  const [filteredSubsubjects, setFilteredSubsubjects] = useState([]);
  const [checkedSubjects, setCheckedSubjects] = useState([]);
  const [checkedSubSubjects, setCheckedSubSubjects] = useState([]);
  // const [value, setValue] = useState(1);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  // const onChange = (e) => {
  //   setValue(e.target.value);
  // };

  useEffect(() => {
    // Fetch data for category selection panel
    getCategories().then((response) => {
      setSubjects(response.data);
    });
    getSubs().then((response) => {
      setSubsubjects(response.data);
      setFilteredSubsubjects(response.data);
    });
  }, []);

  useEffect(() => {
    // Fetch products for the default category "Bridges" on page load
    const loadDefaultCategory = async () => {
      try {
        const response = await getCategories();
        const defaultCategory = response.data.find((c) => c.slug === "bridges");

        if (defaultCategory) {
          setCheckedSubjects([defaultCategory._id]);
          const productsResponse = await getProductsByFilter({
            category: [defaultCategory._id],
          });
          setCategories(productsResponse.data);
        } else {
          // If "Bridges" category not found, load all products
          const allProductsResponse = await getProductsByCount(52);
          setCategories(allProductsResponse.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error loading default category:", error);
      }
    };

    loadDefaultCategory();
  }, []);

  useEffect(() => {
    if (!categories.length && subjects.length) {
      const subjectIDs = subjects
        .filter((product) => product.slug === productsSlug)
        .map((filteredSubjects) => filteredSubjects._id);
      if (subjectIDs.length) {
        getProductsByFilter({ category: [subjectIDs[0]] }).then((res) => {
          setCategories(res.data);
        });
        setCheckedSubjects(subjectIDs);
      } else {
        // Load all product items
        getProductsByCount(52).then((response) => {
          setCategories(response.data);
          setLoading(false);
        });
      }
    }
  }, [subjects]);

  useEffect(() => {
    if (checkedSubjects.length) {
      const updatedSubSubjectChecks = [];
      const subSubjects = subsubjects.filter((item) => {
        const isChecked = checkedSubjects.includes(item.parent);
        if (isChecked && filteredSubsubjects.includes(item._id)) {
          updatedSubSubjectChecks.push(item._id);
        }
        return isChecked;
      });
      setCheckedSubSubjects(updatedSubSubjectChecks);
      setFilteredSubsubjects(subSubjects);
    } else {
      setFilteredSubsubjects(subsubjects);
    }
  }, [subsubjects, checkedSubjects]);

  const fetchProducts = (arg) => {
    getProductsByFilter(arg).then((res) => {
      setCategories(res.data);
    });
  };

  // load items by category
  const showCategories = () =>
    subjects.map((c) => (
      <div key={c._id}>
        <Checkbox
          onChange={handleCheck}
          className="pb-2 pl-4 pr-4"
          value={c._id}
          name="category"
          checked={checkedSubjects.includes(c._id)}
        >
          {c.name}
        </Checkbox>
        <br />
      </div>
    ));

  const handleCheck = (e) => {
    const justChecked = e.target.value;
    let inTheState = [justChecked]; // Clear the array and add the newly checked item

    setCheckedSubjects(inTheState);

    if (!inTheState.length) {
      getProductsByCount(18).then((response) => {
        setCategories(response.data);
        setLoading(false);
      });
    } else {
      fetchProducts({ category: inTheState });
    }
  };

  // Load Subcategories
  const showSubs = () =>
    filteredSubsubjects.map((s) => (
      <div key={s._id}>
        <Checkbox
          onChange={handleCheckSubs}
          className="pb-2 pl-4 pr-4"
          value={s._id}
          name="sub"
          checked={checkedSubSubjects.includes(s._id)}
        >
          {s.name}
        </Checkbox>
        <br />
      </div>
    ));

  // check for sub categories
  const handleCheckSubs = (e) => {
    let inTheState = [...checkedSubSubjects];
    let justChecked = e.target.value;
    let foundInTheState = inTheState.indexOf(justChecked);

    if (foundInTheState === -1) {
      inTheState.push(justChecked);
    } else {
      inTheState.splice(foundInTheState, 1);
    }
    setCheckedSubSubjects(inTheState);
    if (inTheState.length) {
      fetchProducts({ sub: inTheState });
    } else if (checkedSubjects.length) {
      fetchProducts({ category: checkedSubjects });
    } else {
      getProductsByCount(52).then((response) => {
        setCategories(response.data);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mt-5 mb-5">Loading...</h4>
            ) : (
              <h1 className="white mt-n5 mb-3">Applications</h1>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb className="mt-4">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="blue">Applications</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row mt-5">
          <div className="col-md-3">
            <h4>Filter Applications</h4>
            <hr />
            <Menu defaultOpenKeys={["1", "2"]} mode="inline">
              <SubMenu
                key="1"
                title={
                  <span>
                    <h6 className="blue">Application Category</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showCategories()}</div>
              </SubMenu>
              <SubMenu
                key="2"
                title={
                  <span>
                    <h6 className="blue">Application Sub Category</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showSubs()}</div>
              </SubMenu>
            </Menu>
          </div>
          <div className="col-md-9">
            {loading ? (
              <h4 className="blue">Loading...</h4>
            ) : (
              <h4 className="blue">Filter Results</h4>
            )}
            <div className="row pb-5">
              {categories.map((p) => (
                <div key={p._id} className="col-sm-6 col-lg-4 mt-3">
                  <ProductCard product={p} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationBridge;
