import React, { useState } from "react";
import { Select, Radio } from "antd";

const { Option } = Select;

const ProductCreateForm = ({
  handleSubmit,
  handleChange,
  handleFileChange,
  handleVideoFileChange,
  setValues,
  values,
  // handleSubChange,
  handleCategoryChange,
  // categoryOptions,
  subOptions,
  // showCategory,
  showSub,
}) => {
  // destruc
  const {
    title,
    ident,
    sorty,
    description,
    descriptiontwo,
    categories,
    subs,
    featurea,
    featureb,
    featurec,
    featured,
    designa,
    designb,
    designc,
    designd,
    sketchfab,
  } = values;

  const [uploadComponentValue, setUploadComponentValue] = useState(1);

  const onChange = (e) => {
    const radioValue = e.target.value;
    setUploadComponentValue(radioValue);
    if (radioValue === 1) {
      handleVideoFileChange({ target: { name: "video", files: [""] } });
    } else if (radioValue === 2) {
      handleChange({ target: { name: "sketchfab", value: "" } });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={title}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>ID</label>
        <input
          type="text"
          name="ident"
          className="form-control"
          value={ident}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Sort number</label>
        <input
          type="number"
          name="sorty"
          className="form-control"
          value={sorty}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Description 1</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Description 2</label>
        <input
          type="text"
          name="descriptiontwo"
          className="form-control"
          value={descriptiontwo}
          onChange={handleChange}
        />
      </div>
      {/* <div className="form-group">
        <label>Sub Category</label>
        <select
          name="subs"
          className="form-control"
          onChange={handleSubChange}
        >
          <option>Please select</option>
          {subs.length > 0 &&
            subs.map((s) => (
              <option key={s._id} value={s._id}>
                {s.name}
              </option>
            ))}
        </select>
      </div> */}

<div className="form-group">
        <label>Category</label>
        <select
          name="category"
          className="form-control"
          onChange={handleCategoryChange}
        >
          <option>Please select</option>
          {categories.length > 0 &&
            categories.map((c) => (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>

      {/* {showCategory && (
        <div>
          <label>Categories</label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            value={categories}
            onChange={(value) => setValues({ ...values, categories: value })}
          >
            {categoryOptions.length &&
              categoryOptions.map((c) => (
                <Option key={c._id} value={c._id}>
                  {c.name}
                </Option>
              ))}
          </Select>
        </div>
      )} */}
      {showSub && (
        <div>
          <label>Sub Categories</label>
          <Select
            mode="multiple"
            style={{width: "100%"}}
            placeholder="Please select"
            value={subs}
            onChange={(value) => setValues({...values, subs: value})}
          >
            {subOptions.length &&
              subOptions.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.name}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Key Feature 1</label>
          <input
            type="text"
            name="featurea"
            className="form-control"
            placeholder="At least 1 key feature must be entered"
            value={featurea}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 2</label>
          <input
            type="text"
            name="featureb"
            className="form-control"
            value={featureb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 3</label>
          <input
            type="text"
            name="featurec"
            className="form-control"
            value={featurec}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 4</label>
          <input
            type="text"
            name="featured"
            className="form-control"
            value={featured}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Design Requirement 1</label>
          <input
            type="text"
            name="designa"
            className="form-control"
            placeholder="At least 1 design requirement must be entered"
            value={designa}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 2</label>
          <input
            type="text"
            name="designb"
            className="form-control"
            value={designb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 3</label>
          <input
            type="text"
            name="designc"
            className="form-control"
            value={designc}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 4</label>
          <input
            type="text"
            name="designd"
            className="form-control"
            value={designd}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-4 col-md-12">
          <label>Choose 3D component type</label>
          <div className="mt-2">
            <Radio.Group onChange={onChange} value={uploadComponentValue}>
              <Radio value={1}>Sketchfab Link</Radio>
              <Radio value={2}>Video File</Radio>
            </Radio.Group>
          </div>
        </div>
        {uploadComponentValue === 1 ? (
          <div className="form-group col-md-12">
            <label>Sketchfab Link <small>&#40;included /embed at end of link&#41;</small></label>
            <input
              type="text"
              name="sketchfab"
              className="form-control"
              value={sketchfab}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {uploadComponentValue === 2 ? (
          <div className="form-group col-md-12">
            <label className="btn btn-primary mr-2">
              Video File Upload
              <input
                name="video"
                type="file"
                hidden
                accept="video/*"
                onChange={handleVideoFileChange}
              />
            </label>
          </div>
        ) : null}
      </div>
      <br />
      <div className="row pl-3">
        <label className="btn btn-primary mr-2">
          Attach Brochure / Flyer &#40;PDF&#41;
          <input
            name="brochure"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
        <label className="btn btn-primary mr-2">
          Attach Product Matrix &#40;PDF&#41;
          <input
            name="datasheet"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <button className="btn btn-outline-info mt-3">Save</button>
    </form>
  );
};

export default ProductCreateForm;
