import { Image, Button, Breadcrumb } from "antd";
import React from "react";

const Accreditations = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1685454932/track-products/Page-banner_Accreditations_nsjaja.jpg"
              alt="Tiflex has company certifications from BSI and RISQS"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Accreditations</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="blue">Accreditations</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 blue">
              Company Accreditations &amp; Approvals
            </h1>
            <p className="lead p-0 mt-2  mb-5">
              Tiflex / Trackelast are RISQS certified. Many of our material
              grades are approved/specified by Network Rail, London Underground
              and other rail administrations around the world.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 mt-3 p-1 text-center">
            <Image
              className="img-fluid rounded border-right"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1686044124/track-products/600x340-bsi-9001_g3eu0c.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="pt-3 pb-3">BS EN ISO 9001:2015</h5>
            {/* <p className="pt-1">
              Tiflex has, on site in Liskeard, a state of the art laboratory,
              providing us with the facilities to conduct our own in-house
              testing.
            </p> */}
            <Button
              size="large"
              type="primary"
              href="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1678715753/tiflex-documents/ISO-9001_2015-Certificate_uam5la.pdf"
              target="_blank"
            >
              View ISO 9001:2015 Certificate
            </Button>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 p-1 text-center">
            <Image
              className="img-fluid rounded"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1686044124/track-products/600x340-bsi-14001_fmvdlg.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="pt-3 pb-3">BS EN ISO 14001:2015</h5>
            <Button
              size="large"
              type="primary"
              href="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1678715753/tiflex-documents/ISO-14001_2015-Certificate_sr3dmn.pdf"
              target="_blank"
            >
              View ISO 14001:2015 Certificate
            </Button>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 p-1 text-center">
            <Image
              className="img-fluid rounded border-left"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1686044125/track-products/600x340-bsi-45001_uxuvze.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="pt-3 pb-3">BS EN ISO 45001:2018</h5>
            <Button
              size="large"
              type="primary"
              href="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1678715753/tiflex-documents/ISO-45001_2018-Certificate_cmrnbz.pdf"
              target="_blank"
            >
              View ISO 45001:2018 Certificate
            </Button>
          </div>

          <div className="col-12 col-md-6 col-lg-4 mt-3 p-1 text-center offset-lg-4">
            <Image
              className="img-fluid rounded"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1686044124/track-products/600x340-RISQS-verified_vtird2.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="pt-3 pb-3">RISQS</h5>
            <Button
              size="large"
              type="primary"
              href="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1715676723/tiflex-documents/RISQS_Certificate_of_Verification_Expires_13.05.25_cca9ul.pdf"
              target="_blank"
            >
              View RISQS Certificate
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accreditations;
