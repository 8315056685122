import React from "react";
import { Breadcrumb } from "antd";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="white mt-n5 mb-5">Privacy Policy</h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb className="mt-2">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/terms-of-use">
                <span>Terms of Use</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/cookie-policy">
                <span>Cookie Policy</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="blue">
                Privacy Policy
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pb-3">
          <div className="col mt-5">
            <p>
              At Tiflex Ltd we take your privacy seriously and work hard to
              ensure our collection, processing, storage and use of personal
              data conforms to General Data Protection Regulation &#40;GDPR&#41;
              and the Data Protection Act 2018 &#40;DPA 2018&#41; requirements.
              This policy &#40;together with our terms of use and any other
              documents referred to on it&#41; sets out the basis on which any
              personal data collected from you, or provided by you, will be
              processed. Please read the following carefully to understand our
              views and practices regarding your personal data and how we will
              treat it. For the purpose of the General Data Protection
              Regulation &#40;GDPR&#41; and the Data Protection Act 2018
              &#40;DPA 2018&#41; &#40;the “Act”&#41;, the data controller is
              Tiflex Limited, a company registered in England and Wales under
              company number 00394614 and with its registered office at Lion
              House, Oriental Road, Woking, Surrey GU22 8AP &#40;“we”, “us”, or
              “Tiflex Ltd”&#41;.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Information we may collect from you</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>We may collect and process the following data about you:</p>
            <p>
              Information that you provide by filling in forms on our site. This
              includes information provided at the time of registering to use
              our site, subscribing to our service, posting material or
              requesting further services. We may also ask you for information
              when you report a problem with our site; if you contact us, we may
              keep a record of that correspondence; We may from time to time
              also ask you to complete surveys that we use for research
              purposes, although you do not have to respond to them.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">
              How your personal information is collected IP addresses
            </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We may collect information about your computer, including where
              available your IP address, operating system and browser type, for
              system administration and for internal reporting purposes. This is
              statistical data about our users&#39; browsing actions and
              patterns, and does not identify any individual.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Cookies </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and also allows us to improve our
              site. For detailed information on the cookies we use and the
              purposes for which we use them see our Cookie policy.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">
              How and why we use your personal information
            </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We only use the personal information you supply to administer your
              account and to provide the products and services you have
              requested. This may in some circumstances involve passing
              necessary details to other companies within the James Walker Group
              or to third party distributors but only expressly for the purpose
              of providing the service or products you have requested.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Uses made of the Information</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>We use information held about you in the following ways:</p>
            <p>
              To ensure that content from our site is presented in the most
              effective manner for you and for your computer.
            </p>
            <p>
              To provide you with information, products or services that you
              request from us or which we feel may be of interest to you.
            </p>
            <p>
              To carry out our obligations arising from any contracts entered
              into between you and us.
            </p>
            <p>To notify you about changes to our service.</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Keeping you informed</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              From time to time we may also use this information to contact you
              with details about our products and services, new products,
              technical updates or other information we feel may be useful or of
              interest to you.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">
              Who we share your personal information with
            </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              No information will be passed on to third parties outside the
              James Walker Group and its official distribution network without
              first requesting your consent.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Where we store your personal data</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              The data that we collect from you may be transferred to, and
              stored at, a destination outside the European Economic Area
              &#40;“EEA”&#41;. It may also be processed by staff operating
              outside the EEA who work for us or for one of our suppliers. Such
              staff maybe engaged in responding to your enquiry, fulfilment of
              your order or provision of support services. By submitting your
              personal data, you agree to this transfer, storing or processing.
              We will take all steps reasonably necessary to ensure that your
              data is treated securely and in accordance with this privacy
              policy.
            </p>
            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we will do our best to protect
              your personal data, we cannot guarantee the security of your data
              transmitted to our site; any transmission is at your own risk.
              Once we have received your information, we will use strict
              procedures and security features to try to prevent unauthorised
              access.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Your rights</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              You have the right to ask us not to process your personal data for
              marketing purposes. You can exercise this right at any time by
              contacting us through our 'Contact' web page Our website may, from
              time to time, contain links to and from other websites. Please
              note that these websites have their own privacy policies and that
              we do not accept any responsibility or liability for these
              policies. We recommend that you check these policies before you
              submit any personal data to these websites.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Changing your mind</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              When you receive an email from us you can amend your details by
              clicking the preference centre link in the footer of the email.
              Alternatively, you can ask us to remove your details from our
              registers completely by simply sending your request through our
              'Contact Us' web page.
            </p>
            <p>
              This page is about privacy on the website. This is one of the
              three pages detailing our legal notices: We take your privacy
              seriously and will only use your personal information to provide
              information on our products and services. From time to time we
              would like to contact you with details of the products and
              services we provide. If you do not want to receive these
              communications please contact us through any communication you see
              fit detailed on our 'Contact Us' web page.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Disclosure of your information</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We may disclose your personal information to any member of our
              group, which means our subsidiaries, as defined in section 1159 of
              the UK Companies Act 2006 &#40;“the James Walker Group”&#41;.
            </p>
            <p>We may disclose your personal information to third parties:</p>
            <p>
              in the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets; if all or substantially all
              of the assets of any company within the James Walker Group are
              acquired by a third party, in which case personal data held by it
              about its customers will be one of the transferred assets; or if
              we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our terms of use, or any applicable terms and conditions
              of sale or supply and other agreements; or to protect the rights,
              property, or safety of any company within the James Walker Group,
              our customers, or others. This includes exchanging information
              with other companies and organisations for the purposes of fraud
              protection and credit risk reduction.
            </p>
            <p>
              If you do not want us to use your data in this way, please contact
              us.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Access to information</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              GDPR and the Data Protection Act give you the right to access
              information held about you. Your right of access can be exercised
              in accordance with these regulations and any such request should
              be addressed using our 'Contact Us' web page.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Changes to our privacy policy</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Any changes we may make to our privacy policy in the future will
              be posted on this page.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Contact</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Questions, comments and requests regarding this privacy policy are
              welcomed and should be addressed using our 'Contact Us' web page.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
