import React from "react";

const SubForm = ({ handleSubmit, name, setName, ident, setIdent }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label>Name</label>
      <input
        type="text"
        className="form-control"
        onChange={(e) => setName(e.target.value)}
        value={name}
        autoFocus
        required
      />
      <br />
      <label>ID</label>
      <input
        type="text"
        className="form-control"
        onChange={(e) => setIdent(e.target.value)}
        value={ident}
        autoFocus
        required
      />
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </div>
  </form>
);

export default SubForm;