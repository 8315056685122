import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import { getSubs } from "../../../functions/sub";
import { createCategory, getCategories, removeCategory} from "../../../functions/category";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CategoryForm from "../../../components/forms/CategoryForm";
import LocalSearch from "../../../components/forms/LocalSearch";

const CategoryCreate = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  
  // const [sub, setSub] = useState("");
  // const [subs, setSubs] = useState([]);

  // step 1
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    loadCategories();
    // loadSubs();
  }, []);

    const loadCategories = () =>
    getCategories().then((c) => setCategories(c.data));

    // const loadSubs = () =>
    // getSubs().then((s) => setSubs(s.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    // createCategory({ name, parent: sub }, user.token)
    createCategory({ name }, user.token)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        toast.success(`"${res.data.name}" is created`);
        loadCategories();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  const handleRemove = async(slug) => {
    if(window.confirm("Delete?")) {
      setLoading(true)
      removeCategory(slug, user.token)
      .then(res => {
        setLoading(false);
        toast.error(`${res.data.name} deleted`);
        loadCategories();
      })
      .catch(err => {
        if (err.response.status === 400) {
          setLoading(false);
          toast.error(err.response.data);
        }
      });
    }
  };

  // step 3
  // const handleSearchChange = (e) => {
  //   e.preventDefault();
  //   setKeyword(e.target.value.toLowerCase());
  // };

// step 4
  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);


    return (
      <div className="container-fluid p-0">
      <div className="row mx-0 angle-product">
</div>
<div className="container">
<div className="row mt-5">
  <div className="col-md-2">
    <AdminNav />
  </div>
      <div className="col">
      {loading ? (
      <h4 className="blue">Loading...</h4>
      ) : (
        <h4>Create new application category</h4>
      )}

{/* <div className="form-group">
      <label>Parent Sub</label>
      <select
      name="sub"
      className="form-control"
      onChange={e => setSub(e.target.value)}
      >
      <option>Please select</option>
      {subs.length > 0 &&
      subs.map((s) => (
      <option key={s._id} value={s._id}>
      {s.name}
      </option>
      ))}
      </select>
      </div> */}


      <CategoryForm
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      />

      {/* // step 2 */}
      

      <LocalSearch keyword={keyword} setKeyword={setKeyword} />

      {/* step 5 */}
      {categories.filter(searched(keyword)).map((c) =>(
        <div className="alert alert-secondary" key={c._id}>
        {c.name}
        <span  onClick={() => handleRemove(c.slug)} className="btn btn-sm float-right">
        <DeleteOutlined className="text-danger" />
        </span>
        <Link to={`/admin/category/${c.slug}`}>
        <span className="btn btn-sm float-right">
        <EditOutlined className="text-warning" />
        </span>
        </Link>
        </div>
      ))}
      </div>
    </div>
    </div>
  </div>
    );
};

export default CategoryCreate;