import React, { useState } from "react";
import { Menu } from "antd";
import { stack as ReactHamburgerMenu } from "react-burger-menu";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";

import "./hamburger.scss";
import trackelastLogo from "../../images/Trackelast-Logo.svg";

const { SubMenu, Item } = Menu;

const HamburgerMenu = () => {
  let { user } = useSelector((state) => ({ ...state }));
  let dispatch = useDispatch();
  let history = useHistory();

  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    history.push("/login");
  };

  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };

  return (
    <div className="hamburger-menu">
      <Link to="/">
        <div
          style={{
            float: "left",
            width: "130px",
            height: "10px",
            padding: "0px",
          }}
        >
          <img className="col-swap" src={trackelastLogo} alt="logo" />
        </div>
      </Link>
      <ReactHamburgerMenu
        right
        isOpen={isMenuOpen}
        onStateChange={handleStateChange}
      >
        <Menu style={{ width: 256 }} mode="inline">
          <Item key="home">
            <Link onClick={() => handleCloseMenu()} to="/">
              HOME
            </Link>
          </Item>

          <SubMenu key="SubMenu" title="APPLICATIONS">
            <Item key="/applications"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications", state: { product: "light-rail" }, }} >Light Rail</Link></Item>
            <Item key="/applications/1"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications/1", state: { product: "heavy-rail" }, }} >Heavy Rail</Link></Item>
            <Item key="/applications/2"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications/2", state: { product: "bridges" }, }} >Bridges</Link></Item>
            <Item key="/applications/3"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications/3", state: { product: "tunnels" }, }} >Tunnels</Link></Item>
            <Item key="/applications/4"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications/4", state: { product: "cranes" }, }}>Cranes</Link></Item>
            <Item key="/applications/5"><Link onClick={() => handleCloseMenu()} to={{ pathname: "/applications/5", state: { product: "turnouts" }, }}>Turnouts</Link></Item>
        </SubMenu>

          <Item key="about-us">
            <Link onClick={() => handleCloseMenu()} to="/about-us">
              ABOUT US
            </Link>
          </Item>
          <Item key="design-testing">
            <Link onClick={() => handleCloseMenu()} to="/design-testing">
              DESIGN & TESTING
            </Link>
          </Item>
          <Item key="contact-us">
            <Link onClick={() => handleCloseMenu()} to="/contact-us">
              CONTACT US
            </Link>
          </Item>
          <Item key="accreditations">
            <Link onClick={() => handleCloseMenu()} to="/accreditations">
              ACCREDITATIONS
            </Link>
          </Item>
          {user && (
            <SubMenu key="SubMenu5" title="Account">
              {user && user.role === "Administrator" && (
                <Item>
                  <Link onClick={() => handleCloseMenu()} to="/admin/sols">
                    Admin Menu
                  </Link>
                </Item>
              )}
              <Item key="register">
                <Link onClick={() => handleCloseMenu()} to="/register">
                  reg
                </Link>
              </Item>
              <Item key="login">
                <Link onClick={() => handleCloseMenu()} to="/login">
                  login
                </Link>
              </Item>
              <Item onClick={logout}>logout</Item>
            </SubMenu>
          )}
        </Menu>
      </ReactHamburgerMenu>
    </div>
  );
};

export default HamburgerMenu;
