import React from "react";
import { Breadcrumb } from "antd";

const TermsOfUse = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="white mt-n5 mb-5">Terms of Use</h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb className="mt-2">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/privacy-policy">
                <span>Privacy Policy</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/cookie-policy">
                <span>Cookie Policy</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="blue">
                Terms of Use
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pb-3">
          <div className="col mt-5">
            <p>
              This page &#40;together with the documents referred to on it&#41;
              tells you the terms of use on which you may make use of our
              website anti-vibration.solutions &#40;“our site”&#41;, whether as
              a guest or a registered user. Please read these terms of use
              carefully before you start to use the site. By using our site, you
              indicate that you accept these terms of use and that you agree to
              abide by them. If you do not agree to these terms of use, please
              refrain from using our site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Information About Us</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              tiflex.co.uk is a site operated by Tiflex Limited, which was
              formed in 1990 from an amalgamation of two well established
              companies. TICO and The Flexible Cork Company. Tiflex is part of
              the James Walker Group of Companies, which has a combined turnover
              of approximately £200 million. Tiflex was originally based in
              Hipley Street in Woking, Surrey, but since 2000 has had just one
              manufacturing site, based at Liskeard in Cornwall.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Accessing Our Site</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Access to our site is permitted on a temporary basis, and we
              reserve the right to withdraw or amend the service we provide on
              our site without notice &#40;see below&#41;. We will not be liable
              if for any reason our site is unavailable at any time or for any
              period.
            </p>
            <p>
              You are responsible for making all arrangements necessary for you
              to have access to our site. You are also responsible for ensuring
              that all persons who access our site through your internet
              connection are aware of these terms, and that they comply with
              them.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Intellectual Property Rights</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We are the owner or the licensee of all intellectual property
              rights in our site, and in the material published on it. Those
              works are protected by copyright laws and treaties around the
              world. All such rights are reserved.
            </p>
            <p>
              You may print off one copy, and may download extracts, of any
              page&#40;s&#41; from our site for your personal reference and you
              may draw the attention of others within your organisation to
              material posted on our site.
            </p>
            <p>
              You must not modify the paper or digital copies of any materials
              you have printed off or downloaded in any way, and you must not
              use any illustrations, photographs, video or audio sequences or
              any graphics separately from any accompanying text.
            </p>
            <p>
              Our status &#40;and that of any identified contributors&#41; as
              the authors of material on our site must always be acknowledged.
            </p>
            <p>
              You must not use any part of the materials on our site for
              commercial purposes without obtaining a licence to do so from us
              or our licensors.
            </p>
            <p>
              If you print off, copy or download any part of our site in breach
              of these terms of use, your right to use our site will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Reliance on Information Posted</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Commentary and other materials posted on our site are not intended
              to amount to advice on which reliance should be placed. We
              therefore disclaim all liability and responsibility arising from
              any reliance placed on such materials by any visitor to our site,
              or by anyone who may be informed of any of its contents.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Our Site Changes Regularly</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We aim to update our site regularly, and may change the content at
              any time. If the need arises, we may suspend access to our site,
              or close it indefinitely. Any of the material on our site may be
              out of date at any given time, and we are under no obligation to
              update such material.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Our liability</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              The material displayed on our site is provided without any
              guarantees, conditions or warranties as to its accuracy. To the
              extent permitted by law, we, other members of our group of
              companies and third parties connected to us hereby expressly
              exclude:
            </p>
            <p>
              All conditions, warranties and other terms which might otherwise
              be implied by statute, common law or the law of equity; Any
              liability for any direct, indirect or consequential loss or damage
              incurred by any user in connection with our site or in connection
              with the use, inability to use, or results of the use of our site,
              any websites linked to it and any materials posted on it,
              including loss of income or revenue, loss of business, loss of
              profits or contracts, loss of anticipated savings, loss of data,
              loss of goodwill, wasted management or office time in each case
              whether caused by tort &#40;including negligence&#41;, breach of
              contract or otherwise, and even if foreseeable.
            </p>
            <p>
              This does not affect our liability for death or personal injury
              arising from our negligence, nor our liability for fraudulent
              misrepresentation or misrepresentation as to a fundamental matter,
              nor any other liability which cannot be excluded or limited under
              applicable law.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">
              Information About You and Your Visits to Our Site
            </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We process information about you in accordance with our privacy
              policy. By using our site, you consent to such processing and you
              warrant that all data provided by you is accurate.
            </p>
            <p>
              Contracts for the supply of goods, services or information formed
              through our site or as a result of visits made by you are governed
              by our terms and conditions of supply as made available to you at
              the relevant time.
            </p>
            <p>
              We take your privacy seriously and will only use your personal
              information to provide information on our products and services.
              From time to time we would like to contact you with details of the
              products and services we provide. Email: If you do not want to
              receive these communications please contact us through our
              'Contact Us' web page.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h6 className="blue">Acceptable Use</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              You may use our site only for lawful purposes. You may not use our
              site...
            </p>
            <p>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </p>
            <p>
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </p>
            <p>
              For the purpose of harming or attempting to harm minors in any
              way; To send, knowingly receive, upload, download, use or re-use
              any material which does not comply with our content standards
              published on our site; To transmit, or procure the sending of, any
              unsolicited or unauthorised advertising or promotional material or
              any other form of similar solicitation &#40;spam&#41;; or to
              knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h6 className="blue">Viruses, hacking and Other Offences</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              You must not misuse our site by knowingly introducing viruses,
              trojans, worms, logic bombs or other material which is malicious
              or technologically harmful. You must not attempt to gain
              unauthorised access to our site, the server on which our site is
              stored or any server, computer or database connected to our site.
              You must not attack our site via a denial-of-service attack or a
              distributed denial-of service attack.
            </p>
            <p>
              By breaching this provision, you would commit a criminal offence
              under the Computer Misuse Act 1990. We will report any such breach
              to the relevant law enforcement authorities and we will co-operate
              with those authorities by disclosing your identity to them. In the
              event of such a breach, your right to use our website will cease
              immediately. We will not be liable for any loss or damage caused
              by a distributed denial-of-service attack, viruses or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data or other proprietary material
              due to your use of our website or to your downloading of any
              material posted on it, or on any website linked to it.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Linking to our site</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              You may link to our website, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval or endorsement on
              our part where none exists. You must not establish a link from any
              website that is not owned by you. Our website must not be framed
              on any other website. We reserve the right to withdraw linking
              permission without notice. The website from which you are linking
              must comply in all respects with the content standards set out in
              these terms or in any acceptable use policy published on our
              website from time to time. If you wish to make any use of material
              on our website other than that set out above, please contact us
              using our 'Contact Us' web page.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Links from Our Site</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Where our site contains links to other sites and resources
              provided by third parties, these links are provided for your
              information only. We have no control over the contents of those
              sites or resources, and accept no responsibility for them or for
              any loss or damage that may arise from your use of them.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Jurisdiction and Applicable Law</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              The English courts will have non-exclusive jurisdiction over any
              claim arising from, or related to, a visit to our site although we
              retain the right to bring proceedings against you for breach of
              these conditions in your country of residence or any other
              relevant country.
            </p>
            <p>
              These terms of use and any dispute or claim arising out of or in
              connection with them or their subject matter or formation
              &#40;including non-contractual disputes or claims&#41; shall be
              governed by and construed in accordance with the law of England
              and Wales.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Variations</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              We may revise these terms of use at any time by amending this
              page. You are expected to check this page from time to time to
              take notice of any changes we made, as they are binding on you.
              Some of the provisions contained in these terms of use may also be
              superseded by provisions or notices published elsewhere on our
              site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="blue">Your concerns</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              If you have any concerns about material which appears on our site,
              please contact us using our 'Contact Us' web page.
            </p>
            <p>Thank you for visiting our website.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
