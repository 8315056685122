import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCategories } from "../../../functions/category";
import { createSub, removeSub, getSubs } from "../../../functions/sub";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SubForm from "../../../components/forms/SubForm";
import LocalSearch from "../../../components/forms/LocalSearch";

const SubCreate = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [name, setName] = useState("");
  const [ident, setIdent] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subs, setSubs] = useState([]);

  // step 1
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    loadCategories();
    loadSubs();
  }, []);

    const loadSubs = () =>
    getSubs().then((s) => setSubs(s.data));

    const loadCategories = () =>
    getCategories().then((c) => setCategories(c.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    // createSub({ name }, user.token)
    createSub({ name, parent: category, ident, }, user.token)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        setIdent("");
        toast.success(`"${res.data.ident}" is created`);
       loadSubs();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data);
        } else {
          // Handle other types of errors
          toast.error("An error occurred");
        }
      });
  };

  const handleRemove = async(slug) => {
    if(window.confirm("Delete?")) {
      setLoading(true)
      removeSub(slug, user.token)
      .then(res => {
        setLoading(false);
        toast.error(`${res.data.ident} deleted`);
        loadSubs();
      })
      .catch(err => {
        if (err.response.status === 400) {
          setLoading(false);
          toast.error(err.response.data);
        }
      });
    }
  };

// step 4
  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);


    return (
      <div className="container-fluid p-0">
      <div className="row mx-0 angle-product">
</div>
<div className="container">
<div className="row mt-5">
  <div className="col-md-2">
    <AdminNav />
  </div>
      <div className="col">
      {loading ? (
      <h4 className="blue">Loading...</h4>
      ) : (
        <h4>Create new application sub category</h4>
      )}

      <div className="form-group">
      <label>Parent Category</label>
      <select
      name="category"
      className="form-control"
      onChange={e => setCategory(e.target.value)}
      >
      <option>Please select</option>
      {categories.length > 0 &&
      categories.map((c) => (
      <option key={c._id} value={c._id}>
      {c.name}
      </option>
      ))}
      </select>
      </div>

      <SubForm
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      ident={ident}
      setIdent={setIdent}
      />
      
      <LocalSearch keyword={keyword} setKeyword={setKeyword} />

      {/* step 5 */}
      {subs.filter(searched(keyword)).map((s) =>(
        <div className="alert alert-secondary" key={s._id}>
        {s.ident}
        <span  onClick={() => handleRemove(s.slug)} className="btn btn-sm float-right">
        <DeleteOutlined className="text-danger" />
        </span>
        <Link to={`/admin/sub/${s.slug}`}>
        <span className="btn btn-sm float-right">
        <EditOutlined className="text-warning" />
        </span>
        </Link>
        </div>
      ))}
      </div>
    </div>
  </div>
  </div>
    );
};

export default SubCreate;