import React from "react";
// import { Link } from "react-router-dom";

const DesignListItems = ({product}) => {
  const {designa, designb, designc, designd,} = product;
  return (
    <ul className="list-group list-group-mine">
      {designa && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="blue">+</span>
          <span className="label label-default label-pill pull-xs-right small">
         {designa}
        </span>
        </li>
      )}

      {designb && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="blue">+</span>
          <span className="label label-default label-pill pull-xs-right small">
         {designb}
        </span>
        </li>
      )}

      {designc && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="blue">+</span>
          <span className="label label-default label-pill pull-xs-right small">
         {designc}
        </span>
        </li>
      )}

      {designd && (
        <li className="list-group-item borderless bullet-point-container">
          <span className="blue">+</span>
          <span className="label label-default label-pill pull-xs-right small">
         {designd}
        </span>
        </li>
      )}
    </ul>
  );
};

export default DesignListItems;
