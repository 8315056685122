import React from "react";
import { Link } from "react-router-dom";
import { Button, Breadcrumb, Image } from "antd";
import { CommentOutlined } from "@ant-design/icons";

const DesignTesting = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1685454932/track-products/Page-banner_design-testing_o2hurk.jpg"
              alt="List of the most popular industries Tico supply anti-vibration and nosie isolation products to"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Design &amp; Testing</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="blue">
                Design & Testing
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 blue">In-house Design &amp; Testing</h1>
            <p className="lead p-0 mt-2">
              Trackelast offers a full technical service &#40;supported by our
              in-house, modern R&D Facilities&#41; to provide you with
              assistance in specifying and selecting the correct materials - to
              provide you with the outcome which you require. Trackelast pads
              are designed and manufactured to the requirements of National /
              rail authority specifications globally. This may involve the
              bespoke design and manufacturer of specialist products. We do not
              just offer standard &#39;off the shelf&#39; products. We are here
              to help our customers develop specialist bespoke solutions to
              their particular challenges supported by our polymer
              technologists, to develop solutions to a variety of engineering
              problems particularly those requiring high performance and product
              durability.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1685458172/track-products/600x340-design-testing-tab-1_z2iwiw.png"
              alt="bespoke bearing assembly"
              preview={false}
            />
            <h5 className="blue pt-3">
              Tensometer &#40;Tensile Testing Machine&#41;
            </h5>
            <p>
              Used for evaluating material properties in tension, for example
              tear strength, tensile strength, modulus & elongation.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1690471069/track-products/600x340-design-testing-tab-2_rinyna.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="blue pt-3">Mayes Compression Testing Machine</h5>
            <p>
              Used for measuring the static and dynamic properties of materials
              in compression, for example, the static and dynamic stiffness of
              rail pads, baseplate pads, undersleeper pads and ballast mats.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1685458172/track-products/600x340-design-testing-tab-3_ohyvw1.png"
              alt="bespoke bearing assembly"
              preview={false}
            />
            <h5 className="blue pt-3">Multi-Function Rheometer</h5>
            <p>
              Used for evaluating the rheological properties (e.g. viscosity,
              curing system/profile and curing conditions) of polymers and
              compounds. Used extensively for research and development of new
              compounds and quality control.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1690471069/track-products/600x340-design-testing-tab-4_pbpbzy.png"
              alt="Laddish bearings designed for large scale forging hammers"
              preview={false}
            />
            <h5 className="blue pt-3">Taber Abrader</h5>
            <p>
              Used for testing the durability of materials and their resistance
              to abrasion.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="col back">
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-center mt-5 pt-5 pt-3">
                {" "}
                <CommentOutlined
                  className="white"
                  style={{ fontSize: "1200%" }}
                />
              </div>
              <div className="col-md-8 mt-5 pt-5 pb-5">
                <h3 className="white">
                  Contact one of our Team Members to find out more.
                </h3>
                <p className="white lead">
                  All of our products are supported by full commercial and
                  technical literature and supported by a technical team to
                  provide you with pre-contract and on-site assistance.
                </p>
                <Link to="contact-us">
                  <Button type="primary" size="large" className="mt-2">
                    Contact Us
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default DesignTesting;
