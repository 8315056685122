import React from "react";
import { DownCircleOutlined } from "@ant-design/icons";

const DesignGuidance = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="white mt-n5 mb-5">Design Guidance</h1>
            </div>
          </div>
          <div className="row pt-3 pb-3">
            <div className="col text-align-center">
              <h5>Web page under construction.</h5>
              {/* <p>
                Please telephone, email or use the &#39;Contact Us&#39; facility
                for any project requirements.
              </p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3 mb-3 bg">
              {/* <p className="pt-5 pl-3 pr-3 lead">
                Any questions or technical issues, our friendly team is here to
                help.
              </p> */}
              <h6 className="blue pt-5 pl-3 pr-4">
                Network Rail Design Guidance
              </h6>
              <ul className="no-bullets pl-3 pr-4">
                <li className="pt-3 pb-5">
                  <DownCircleOutlined
                    style={{ fontSize: "20px", float: "left" }}
                    className="blue pr-2"
                  />
                  Click to view document
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignGuidance;
