
// import * as firebase from "firebase/app"; // old way, wont work anymore
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";

// firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBe207mKG0sTHA90ycelVaPLvHqIRUQ5RM",
  authDomain: "trackelast.firebaseapp.com",
  projectId: "trackelast",
  storageBucket: "trackelast.appspot.com",
  messagingSenderId: "357551906767",
  appId: "1:357551906767:web:c932464a3f6b9952a5bcba"
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
// export
// export default firebase;
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
