import React from "react";
import trackelastLogoWhite from "../../images/Trackelast-Logo-White.svg";
import tiflexDivision from "../../images/A-division-of-Tiflex.svg";
import railForumLogo from "../../images/rail-forum-logo.svg";
import { Link } from "react-router-dom";
import { LinkedinFilled } from "@ant-design/icons";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer p-0">
        <div className="container">
          <div className="row footer foot pt-5">
            <div className="col-12 col-md-3">
              <a href="/">
                {" "}
                <img
                  className="foot-logo"
                  src={trackelastLogoWhite}
                  alt="logo"
                />
              </a>
            </div>
            <div className="col-6 col-md-3">
              <Link
                exact
                to={{
                  pathname: "/applications",
                  state: { product: "light-rail" },
                }}
              >
                <p>Applications</p>
              </Link>
              <Link to="/about-us">
                <p>About Trackelast</p>
              </Link>
              <Link to="/design-testing">
                <p>Design &amp; Testing</p>
              </Link>
            </div>
            <div className="col-6 col-md-3">
              <Link to="/contact-us">
                <p>Contact Us</p>
              </Link>
              <Link to="/accreditations">
                <p>Accreditations</p>
              </Link>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column">
              <a
                href="https://www.linkedin.com/showcase/trackelast/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Trackelast</p>
              </a>
              <a
                href="https://www.linkedin.com/in/danny-millard-72700735/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Danny Millard</p>
              </a>
              <a
                href="https://www.linkedin.com/in/chris-clarke-85872714/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Chris Clarke</p>
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pt-3 pb-3">
            <div className="col-12 col-md-1 offset-md-1 text-center foot">
              <a
                href="https://www.tiflex.co.uk/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="foot-div-logo ml-3"
                  src={tiflexDivision}
                  alt="A division of Tiflex logo"
                />
              </a>
            </div>
            <div className="col-12 col-md-9 text-center foot pt-2">
              <p className="d-inline pr-3 small">
                Trackelast 2024. All rights reserved.
              </p>
              <Link to="/terms-of-use">
                <p className="d-inline pr-3 small">Terms of Use</p>
              </Link>
              <Link to="/privacy-policy">
                <p className="d-inline pr-3 small">Privacy Policy</p>
              </Link>
              <Link to="/cookie-policy">
                <p className="d-inline pr-3 small">Cookie Policy</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="pt-3 pb-3">
          <div className="col-12 text-center p-3 bg-white">
            <p className="d-inline pr-3 small blue">Members of</p>
            <a
              href="https://www.railforum.uk/members/tiflex-limited-trackelast/"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              <img
                className="rf-logo"
                src={railForumLogo}
                alt="logo for our Rail Forum membership"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
