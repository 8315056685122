import { Button, Modal } from "antd";
import React, { useState } from "react";

const HomepageModal = ({ title }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        className="mb-4 btn-edit"
        size="large"
        onClick={() => setVisible(true)}
      >
        VIEW INTERACTIVE 3D MODEL
      </Button>
      <Modal
        title="Trackelast's products & applications - 1: Light Rail, 2: Heavy Rail, 3: Bridges, 4: Tunnels, 5: Cranes, 6: Turnouts"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1200}
      >
        <div class="sketchfab-embed-wrapper">
          {" "}
          <iframe
            title="Trackelast applications and products in situ"
            width="100%"
            height="600px"
            frameborder="0"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autostart; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src="https://sketchfab.com/models/4ae58f691c8f4b64b96651d02029a248/embed?autostart=1"
          >
            {" "}
          </iframe>{" "}
        </div>
      </Modal>
    </>
  );
};



export default HomepageModal;
